import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Button from 'reactstrap/lib/Button';
import { withModalActions, withModalData } from 'core/hocs';
import { FormattedTag } from 'core/components';

import { ModalWindow } from 'components/modal-window/modal-window';
import { IconInfoDeposit } from 'components/icons/icon-info/icon-info-deposit';
import { MODAL_IDS } from '../../../constants';

import './deposit-how-to-modal.scss';

class DepositHowToModalUI extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    currentModal: PropTypes.shape({
      id: PropTypes.string,
      data: PropTypes.any
    }).isRequired,
    intl: intlShape.isRequired,
  };

  closeModal = () => {
    const {
      closeModal,
    } = this.props;

    closeModal(MODAL_IDS.DEPOSIT_HOW_TO);
  }

  render() {
    const { currentModal, intl } = this.props;

    const paymentMethod = currentModal?.data?.paymentMethod ?? '';
    const howToLinkKey = `deposit.how-to-${paymentMethod.toLowerCase()}-link`;
    const howToLinkKeyResult = intl.formatMessage({ id: howToLinkKey });

    return (
      <ModalWindow
        id={MODAL_IDS.DEPOSIT_HOW_TO}
        coreProps={{ id: MODAL_IDS.DEPOSIT_HOW_TO }}
        bodyClassName="d-flex d-sm-block align-items-center flex-column justify-content-center text-center"
        wrapClassName="wrapper"
        isModalCentered
        isModalAlignTop={false}
        centered
        fade
      >
        <FormattedTag
          tag="div"
          id="deposit.how-to"
          className="h2 mb-1 text-center text-blue-dark font-family-oswald text-uppercase"
        />
        <FormattedTag
          tag="div"
          id={`deposit.how-to-${paymentMethod.toLowerCase()}`}
          className="text-blue-dark mb-2_5 mb-sm-3 text-center text-small text-sm-base w-100"
          isHtml
        />

        {howToLinkKey !== howToLinkKeyResult && (
          <a
            href={howToLinkKeyResult}
            target="_blank"
            rel="noreferrer"
            className="d-flex align-items-center justify-content-center w-100 full-link text-decoration-none"
          >
            <IconInfoDeposit className="mr-1 my-auto" />
            <FormattedTag
              color="primary"
              id="deposit.how-to-full"
            />
          </a>
        )}

        <FormattedTag
          tag={Button}
          color="primary"
          className="px-4_25 mt-1 w-100"
          id="deposit.how-to-ok"
          onClick={this.closeModal}
        />
      </ModalWindow>
    );
  }
}

export const DepositHowToModal = withModalActions(withModalData(injectIntl(DepositHowToModalUI)));
