import React, { Component, Fragment } from 'react';
import Button from 'reactstrap/lib/Button';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import { parse as parseQueryString } from 'qs';
import { withRouter } from 'react-router-dom';
import { Deposit as DepositCore, FormattedTag } from 'core/components';
import {
  withCoreComponent, withModalActions, withUser, withGlobalEvents, withUserAgent, withIsInsideReactNativeApp
} from 'core/hocs';
import { USER_FIELDS, GLOBAL_EVENTS } from 'core/constants';
import {
  isEmptyOrNil, isPaymentMethodIFrame, pushMessageToReactNativeApp
} from 'core/helpers';
import { PaymentsPreloader } from 'components/payments-preloader/payments-preloader';
// import { PaymentSuccessModal } from 'components/modals/payment-success-modal/payment-success-modal';
import { IconSuccess } from 'components/icons/icon-notification/icon-success';
import { DepositNew } from './deposit-new/deposit-new';
import { DepositOptions } from './deposit-options/deposit-options';
import { MODAL_IDS } from '../../constants';
// import { GA } from '../../helpers/ga';
import { withModalAlignment } from '../../hocs/with-modal-alignment';
// import { YM } from '../../helpers/ym';

import './deposit.scss';

const STEPS = {
  START: 0,
  FINISH: 1,
  PAYMENT_REDIRECT: 2,
  PAYMENT_NO_REDIRECT: 3,
};

const PAYMENTS_WRAPPER_CLASS = 'payments-wrapper';

const mediaQueryString = '(max-width: 719px)';

export class DepositUI extends Component {
  state = {
    step: STEPS.START,
    depositNewIsAvailable: true,
    depositMethod: null,
    isMobileSize: false,
    isMounted: false,
    redirectUrl: '',
    isUserChangeMethod: false,
    paymentId: '',
  };

  static getDerivedStateFromProps(props, prevState) {
    const {
      // location: { search },
      setActiveDepositMethod,
      isModal,
    } = props;

    // const queryParams = parseQueryString(search, { ignoreQueryPrefix: true });
    //
    // if (Object.keys(queryParams).includes('payment_id')) {
    //   GA.event({ category: 'deposit', action: 'click', label: 'DepComplete' });
    //   YM.event('DepComplete');
    // }

    const {
      activeDepositMethod,
      items,
    } = props;

    const {
      depositMethod,
      step,
      isUserChangeMethod,
    } = prevState;

    if (isEmptyOrNil(items)) {
      return null;
    }

    if (items.lastRecentlyMethod) {
      if (!isUserChangeMethod && !activeDepositMethod && isModal) {
        setActiveDepositMethod(items.lastRecentlyMethod);
      }
    }

    if (depositMethod !== activeDepositMethod && step !== STEPS.PAYMENT_REDIRECT
      && step !== STEPS.PAYMENT_NO_REDIRECT) {
      const isActiveDepositMethodEmpty = isEmptyOrNil(activeDepositMethod);

      return {
        depositMethod: activeDepositMethod,
        ...(isActiveDepositMethodEmpty ? {
          step: STEPS.START,
          depositNewIsAvailable: true,
        } : {
          step: STEPS.FINISH,
        }),
      };
    }

    return null;
  }

  componentDidMount() {
    const mql = window.matchMedia(mediaQueryString);
    const { isSignUpCompleted, openModal } = this.props;
    this.setState({ isMounted: true });

    if (!isSignUpCompleted) {
      openModal(MODAL_IDS.COMPLETE_SIGN_UP);

      return;
    }

    mql.addListener(this.updateDeviceType);
    this.updateDeviceType();

    const { globalEvents } = this.props;

    globalEvents.on(GLOBAL_EVENTS.PAYMENT_REDIRECT, this.goToPaymentRedirect);
    globalEvents.on(GLOBAL_EVENTS.PAYMENT_NO_REDIRECT, this.goToPaymentNoRedirect);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      depositNewIsAvailable: prevDepositNewIsAvailable, isMobileSize: prevIsMobileSize, isMounted: prevIsMounted,
    } = prevState;
    const {
      depositNewIsAvailable, isMobileSize, step, isMounted,
    } = this.state;
    const { isModal, isInProgress: prevIsInProgress } = prevProps;
    const { isInProgress, updateModalAlignment } = this.props;

    if (isModal && (!isInProgress && isInProgress !== prevIsInProgress) || (isMounted && isMounted !== prevIsMounted)) {
      updateModalAlignment();
    }

    if (isMobileSize !== prevIsMobileSize || (isMobileSize && step === STEPS.START)) {
      this.applyMobileDesign(step);
    }

    if (prevDepositNewIsAvailable === depositNewIsAvailable) {
      return;
    }

    const popup = document.querySelector('.deposit-options-small');

    const wrapperPopup = document.querySelector('.wrapper-deposit-options-small');
    const popupHeight = popup.offsetHeight;
    const overflowingElement = isModal ? document.querySelector('.modal-content') : document.body;

    if (!depositNewIsAvailable) {
      wrapperPopup.style.height = `${popupHeight}px`;

      if (isMobileSize) {
        overflowingElement.style.overflow = 'hidden';

        if (popupHeight >= window.innerHeight - 100) {
          wrapperPopup.classList.add('active-long');
          wrapperPopup.style.bottom = `${window.innerHeight - 100 - popupHeight}px`;
          popup.style.height = `${popupHeight - window.innerHeight + 100 + popupHeight}px`;
        }
      }
    } else {
      document.querySelector('.wrapper-deposit-options-small').style.height = '0';
      overflowingElement.style.overflow = '';

      if (popup.classList.contains('active-long')) {
        wrapperPopup.classList.remove('active-long');
      }
    }
  }

  componentWillUnmount() {
    const mql = window.matchMedia(mediaQueryString);
    mql.removeListener(this.updateDeviceType);
    const { globalEvents } = this.props;

    globalEvents.off(GLOBAL_EVENTS.PAYMENT_REDIRECT, this.goToPaymentRedirect);
    globalEvents.off(GLOBAL_EVENTS.PAYMENT_NO_REDIRECT, this.goToPaymentNoRedirect);
  }

  goToFinish = () => {
    this.setState({ step: STEPS.FINISH });

    this.applyMobileDesign(STEPS.FINISH);
  };

  goToStart = () => {
    const { setActiveDepositMethod } = this.props;

    this.setState({
      step: STEPS.START,
      isUserChangeMethod: true,
      paymentId: '',
    });
    setActiveDepositMethod(null);
  };

  goToPaymentRedirect = ({ payload: { url } }) => {
    const {
      isPwa,
      activeDepositMethod,
    } = this.props;
    const isPaymentMethodInIFrame = isPaymentMethodIFrame(activeDepositMethod);

    if (isPaymentMethodInIFrame) {
      return;
    }

    if ((!isPaymentMethodInIFrame && isPwa) || !isPwa) {
      this.setState({ step: STEPS.PAYMENT_REDIRECT, redirectUrl: url });
    }
  };

  goToPaymentNoRedirect = ({ payload: { paymentId } }) => this.setState({ step: STEPS.PAYMENT_NO_REDIRECT, paymentId });

  handleDepositOptions = (event) => {
    const { step } = this.state;
    const { setActiveDepositMethod, isSignUpCompleted, openModal } = this.props;

    if (!isSignUpCompleted) {
      openModal(MODAL_IDS.COMPLETE_SIGN_UP);

      return;
    }

    if (step === STEPS.START) {
      this.goToFinish();
    } else {
      this.toggleDepositNew();
    }

    const item = event.currentTarget;

    setActiveDepositMethod(item.dataset.name);
  };

  toggleDepositNew = () => {
    this.setState(prevState => ({
      depositNewIsAvailable: !prevState.depositNewIsAvailable,
    }));
  };

  updateDeviceType = (e) => {
    const isMobile = e ? e.target.matches : window.matchMedia(mediaQueryString).matches;

    this.setState({ isMobileSize: isMobile });
  }

  applyMobileDesign = (step) => {
    const { isMobileSize } = this.state;
    const { isModal } = this.props;
    const bodyClassesList = document.body.classList;

    if (isModal) {
      return;
    }

    if (step === STEPS.START || !isMobileSize) {
      if (bodyClassesList.contains(PAYMENTS_WRAPPER_CLASS)) {
        bodyClassesList.remove(PAYMENTS_WRAPPER_CLASS);
      }

      return;
    }

    if (isMobileSize) {
      if (bodyClassesList.contains(PAYMENTS_WRAPPER_CLASS)) {
        return;
      }

      bodyClassesList.add(PAYMENTS_WRAPPER_CLASS);
    }
  }

  handleModalClose = () => {
    const { closeModal } = this.props;

    closeModal(MODAL_IDS.DEPOSIT);
  }

  onContinueButtonInsideReactNativeAppClick = () => {
    const { redirectUrl } = this.state;
    const { isModal } = this.props;
    (isModal ? this.handleModalClose : this.goToStart)();
    pushMessageToReactNativeApp({ event: 'openPaymentMobileScreen', params: { url: redirectUrl } });
  }

  render() {
    const {
      step,
      depositNewIsAvailable,
      isMounted,
      redirectUrl,
      paymentId,
    } = this.state;

    const {
      items,
      currency,
      activeDepositMethod,
      isModal,
      isInProgress,
      updateModalAlignment,
      isPwa,
      isInsideReactNativeApp,
    } = this.props;

    const isRedirectStep = step === STEPS.PAYMENT_REDIRECT;
    const isTitleShown = isModal && ![STEPS.PAYMENT_REDIRECT, STEPS.PAYMENT_NO_REDIRECT].includes(step);

    return (
      <div className={classNames('deposit d-flex flex-column', {
        'px-2 text-blue-dark is-modal mt-0 pt-5 pt-sm-6': isModal,
        'mt-1 mt-sm-2_5': !isModal,
      })}
      >
        {isTitleShown && (
          <div className={classNames('title-wrapper w-100 bg-white', { 'is-modal pb-2 px-2 pt-2': isModal })}>
            <FormattedTag
              className={classNames('h2 mt-0_25 mb-0_5 ml-md-2_5 mt-sm-1 text-uppercase font-family-oswald', { 'ml-md-4_5': !isModal })}
              id="deposit"
              isHtml
            />
          </div>
        )}

        {(isInProgress || !isMounted)
          ? (
            <div className={classNames({ 'mx-4 mt-3': isModal })}>
              <PaymentsPreloader />
            </div>
          ) : step === STEPS.START && !isEmptyOrNil(items) && (
            <DepositOptions
              className={classNames('card-width', { 'bg-transparent mt-3_25 px-sm-2_5 mb-sm-3 mt-sm-2_5': isModal }, { 'deposit-options-bg pb-3': !isModal })}
              items={items}
              handleDepositOptions={this.handleDepositOptions}
              currency={currency}
              isModal={isModal}
              handleModalClose={this.handleModalClose}
            />
          )}

        {step === STEPS.FINISH && (
          <Fragment>
            <DepositNew
              updateModalAlignment={updateModalAlignment}
              className={classNames('flex-grow-1', { 'd-flex d-sm-none': !depositNewIsAvailable }, { 'bg-transparent pt-sm-1': isModal })}
              handlerPaymentChangeForMobile={this.toggleDepositNew}
              handlerPaymentChangeForDesktop={this.goToStart}
              activeDepositMethod={activeDepositMethod}
              handlerStep={this.goToStart}
              isModal={isModal}
            />
            <div
              className={classNames('wrapper-deposit-options-small w-100',
                {
                  'd-block d-sm-none': !depositNewIsAvailable,
                  'fixed-bottom': !depositNewIsAvailable,
                  'd-none': depositNewIsAvailable,
                })}
            >
              <div
                role="presentation"
                onClick={this.toggleDepositNew}
                className={classNames('overlay d-block fixed-top vw-100 h-100', { active: !depositNewIsAvailable })}
              />
              {!isEmptyOrNil(items) && (
                <DepositOptions
                  className={classNames('deposit-options-small card-width pt-3 pb-2')}
                  items={items}
                  handleDepositOptions={this.handleDepositOptions}
                  currency={currency}
                />
              )}
            </div>
          </Fragment>
        )}
        {isRedirectStep && (
          <div
            className={classNames('d-flex flex-column align-items-center justify-content-center card-width text-center rounded pt-2_5 mt-13 mt-sm-0 py-sm-6 deposit-options-bg', { 'bg-transparent ': isModal }, { 'redirect-step': !isModal })}
          >
            <IconSuccess className="mb-2_5" />

            <FormattedTag
              className="h3 text-uppercase"
              id="profile.deposit-redirect.title"
              isHtml
            />

            <FormattedTag
              className="text-center mt-1_25"
              tag="div"
              id="profile.deposit-redirect.message"
              isHtml
            />
            {isInsideReactNativeApp ? (
              <Button
                className="mt-4"
                onClick={this.onContinueButtonInsideReactNativeAppClick}
              >
                <FormattedTag id="continue" isHtml />
              </Button>
            ) : (
              <Button
                tag="a"
                href={redirectUrl}
                className="mt-4"
                onClick={isModal ? this.handleModalClose : this.goToStart}
                target={isPwa ? '_self' : '_blank'}
              >
                <FormattedTag id="continue" isHtml />
              </Button>
            )}
          </div>
        )}

        {step === STEPS.PAYMENT_NO_REDIRECT && (
          <div className={classNames('d-flex flex-column align-items-center justify-content-center w-100 h-100', { 'p-3_75': isModal })}>
            <div className="no-redirect d-flex flex-column align-items-center justify-content-center">
              <IconSuccess />
              <FormattedTag
                tag="div"
                className="text-uppercase mt-2 h3"
                id="confirmed"
                isHtml
              />
              <FormattedTag
                tag="div"
                className="text-center mt-1 mb-1"
                id="payment.no-redirect.ref-number"
                values={{ paymentId }}
                isHtml
              />
              <FormattedTag
                tag="div"
                className="text-center mb-3_75"
                id="payment.no-redirect.support"
                isHtml
              />
              <Button color="primary" onClick={this.goToStart}>
                <FormattedTag id="continue" isHtml />
              </Button>
            </div>
          </div>
        )}

        {/* <PaymentSuccessModal isInProgress={isInProgress} isDeposit /> */}
      </div>
    );
  }
}

DepositUI.propTypes = {
  isInProgress: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
  setActiveDepositMethod: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  activeDepositMethod: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  items: PropTypes.shape({
    recently: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        paymentMethod: PropTypes.string,
        minimumDeposit: PropTypes.number,
        feeRate: PropTypes.number,
        conversionRequired: PropTypes.bool,
        maximumDeposit: PropTypes.number,
        popupRequired: PropTypes.bool,
        method: PropTypes.string,
      }),
    ),
    other: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  currency: PropTypes.string.isRequired,
  isModal: PropTypes.bool,
  isSignUpCompleted: PropTypes.bool.isRequired,
  globalEvents: PropTypes.shape({
    on: PropTypes.func,
    off: PropTypes.func,
  }).isRequired,
  isPwa: PropTypes.bool.isRequired,
  updateModalAlignment: PropTypes.func,
  isInsideReactNativeApp: PropTypes.bool.isRequired,
};

DepositUI.defaultProps = {
  activeDepositMethod: '',
  items: {
    recently: [],
    other: [],
  },
  isModal: false,
  updateModalAlignment: null,
};

export const Deposit = withCoreComponent(DepositCore, withModalActions(
  withUser(withRouter(withGlobalEvents(withUserAgent(withModalAlignment(withIsInsideReactNativeApp(DepositUI))))),
    [USER_FIELDS.IS_SIGN_UP_COMPLETED]),
));
